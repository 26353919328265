import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'

import Seo from '../components/seo'
import Link from '../utils/link'
import ProductCard from '../components/cards/product'
import ReadyToGetStarted from '../components/ready-to-get-started'
import tag from "../utils/tag"

class Product extends Component {

  state = {
    variant: this.props.data.shopifyProduct.variants[0],
    price: this.props.data.shopifyProduct.variants[0].price,
    accordionOpen: false,
    thumbActive: false,
    image: null,
    qty: 1,
  }

  settings = {
    infinite: true,
    speed: 600,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    cssEase: 'ease',
    pauseOnHover: false,
    focusOnSelect: false,
  }

  componentDidMount() {
    this._toggleAccordion(0)
    this._slickGoTo(0)
    tag("Product View", this.props.data.shopifyProduct.title)
  }

  _selectVariant = (variant) => () => {
    this.setState({
      variant,
      price: variant.price,
      image: variant,
    })
  }

  _slickGoTo = (index) => {
    let { thumbActive } = this.state
    if ( thumbActive[index] ) return
    this.setState({thumbActive: {[index]: !this.state.thumbActive[index]} })
    this.slider.slickGoTo(index)
  }

  _getHexColour = (colour) => {
    let { colours } = this.props.data.wp.siteOptions.settings
    let result = colours.find(item => item.name === colour)
    if (result) {
      return result.colour
    }
    return false
  }

  _addToCart = () => {
    let { variant, qty } = this.state
    tag("Add to Cart", this.props.data.shopifyProduct.title+' - '+variant.title, qty)


    setTimeout(() => {
      this.props.addVariantToCart(variant.storefrontId, qty)
    }, 100)
  }

  _toggleAccordion = (accordionItem) => {
    let { accordionOpen } = this.state
    if ( accordionOpen[accordionItem] ) return
    this.setState({accordionOpen: {[accordionItem]: !this.state.accordionOpen[accordionItem]} })
  }

  render() {
    const product = this.props.data.shopifyProduct
    const wpProduct = this.props.data.wpProduct
    const wp = this.props.data.wp
    const related = this.props.data.allWpProduct.nodes

    let { variant, price, image, accordionOpen, thumbActive } = this.state

    const variantImage = variant.image && getShopifyImage({
      image: variant.image,
      width: 800,
    })

    return (
      <>
        <Seo
          bodyClass='single-product'
          {...wpProduct.seo}
          product={product}
        />
        <article className="single-product__wrapper">
          <section className='breadcrumbs'>
            <div className='breadcrumbs__inner'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/shop/'>Shop Now</Link></li>
                <li>{ product.title }</li>
              </ul>
            </div>
          </section>
          <div className='single-product__inner'>
            <section className='single-product__gallery'>
              { product.images.length > 0 &&
                <>
                  <div className='single-product__thumbs'>
                    { product.images.map((el, i) => {
                      let image = el && getShopifyImage({
                        image: el,
                        width: 200,
                      })
                      return (
                        <GatsbyImage image={image} alt={product.title} onClick={() => this._slickGoTo(i)} className={`${thumbActive[i] && 'active'}`} />
                      )
                    })}
                  </div>
                  <div className='single-product__slider'>
                    <Slider ref={slider => (this.slider = slider)} {...this.settings}>
                      { product.images.map((el, i) => {
                        let image = el && getShopifyImage({
                          image: el,
                          width: 1000,
                        })
                        return (
                          <GatsbyImage image={image} alt={product.title} />
                        )
                      })}
                    </Slider>
                  </div>
                </>
              }
            </section>
            <section className='single-product__info'>
              <div className='single-product__title'>
                <h1>{product.title}</h1>
              </div>
              <div className='single-product__price'>
                <span>${parseFloat(price).toLocaleString('en')}</span>
              </div>
              { product.variants.length > 1 &&
                <>
                  <span className='single-product__current-variant'>Colour: <strong>{variant.title}</strong></span>
                  <div className='single-product__variations'>
                    { product.variants.map((el, i) => {
                      let props = {
                        key: i,
                        className: `btn btn--variation ${el.storefrontId === variant.storefrontId ? 'active' : ''}`,
                        onClick: this._selectVariant(el),
                      }
                      let style = {
                        backgroundColor: this._getHexColour(el.title)
                      }
                      return (<button {...props} style={style} />)
                    }) }
                  </div>
                </>
              }
              <div className='single-product__buttons'>
                <div className='form__select'>
                  <select name='qty' onChange={(e) => this.setState({ qty: e.target.value })}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                  </select>
                </div>
                <button className='btn--cart btn' onClick={this._addToCart}>Add to cart</button>
              </div>
              <div className='single-product__description'>
                <div className='single-product__content' dangerouslySetInnerHTML={{ __html: wpProduct.content }} />
              </div>
            </section>
          </div>
          <section className='single-product__tabs-header'>
            <div className='single-product__inner'>
              <ul>
                <li>
                  <button type='button' className={`${accordionOpen[0] && 'active'}`} onClick={() => this._toggleAccordion(0)}>
                    Product Information
                  </button>
                </li>
                <li>
                  <button type='button' className={`${accordionOpen[1] && 'active'}`} onClick={() => this._toggleAccordion(1)}>
                    Specifications
                  </button>
                </li>
                <li>
                  <button type='button' className={`${accordionOpen[2] && 'active'}`} onClick={() => this._toggleAccordion(2)}>
                    Warranty &amp; Returns
                  </button>
                </li>
                <li>
                  <button type='button' className={`${accordionOpen[3] && 'active'}`} onClick={() => this._toggleAccordion(3)}>
                    Documents &amp; Downloads
                  </button>
                </li>
                <li>
                  <button type='button' className={`${accordionOpen[4] && 'active'}`} onClick={() => this._toggleAccordion(4)}>
                    Delivery &amp; Support
                  </button>
                </li>
              </ul>
            </div>
          </section>
          <section className='single-product__tabs-content'>
            <div className='single-product__inner'>
              <div className='single-product__mobile-tab'>
                <button type='button' className={`${accordionOpen[0] && 'active'}`} onClick={() => this._toggleAccordion(0)}>
                  Product Information
                </button>
              </div>
              <div className={`single-product__tab-content ${accordionOpen[0] && 'active'}`}>
                <div className='single-product__tab-row'>
                  <h4>Description</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.description }} />
                </div>
                <div className='single-product__tab-row'>
                  <h4>SKU/Product ID</h4>
                  <div dangerouslySetInnerHTML={{ __html: variant.sku }} />
                </div>
                <div className='single-product__tab-row'>
                  <h4>Features</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.features }} />
                </div>
              </div>
              <div className='single-product__mobile-tab'>
                <button type='button' className={`${accordionOpen[1] && 'active'}`} onClick={() => this._toggleAccordion(1)}>
                  Specifications
                </button>
              </div>
              <div className={`single-product__tab-content ${accordionOpen[1] && 'active'}`}>
                <div className='single-product__tab-row'>
                  <h4>Width (mm)</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.width }} />
                </div>
                <div className='single-product__tab-row'>
                  <h4>Length (mm)</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.length }} />
                </div>
                <div className='single-product__tab-row'>
                  <h4>Depth (mm)</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.drop }} />
                </div>
                { wpProduct.acf.specifications?.length > 0 && wpProduct.acf.specifications.map((el, i) => (
                  <div className='single-product__tab-row' key={i}>
                    <h4>{el.label}</h4>
                    <div dangerouslySetInnerHTML={{ __html: el.value }} />
                  </div>
                ))}
              </div>
              <div className='single-product__mobile-tab'>
                <button type='button' className={`${accordionOpen[2] && 'active'}`} onClick={() => this._toggleAccordion(2)}>
                  Warranty &amp; Returns
                </button>
              </div>
              <div className={`single-product__tab-content ${accordionOpen[2] && 'active'}`}>
                <div className='single-product__tab-row'>
                  <h4>Warranty &amp; Returns</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.warrantyReturns ? wpProduct.acf.warrantyReturns : wp.siteOptions.settings.warrantyReturns }} />
                </div>
              </div>
              <div className='single-product__mobile-tab'>
                <button type='button' className={`${accordionOpen[3] && 'active'}`} onClick={() => this._toggleAccordion(2)}>
                  Documents &amp; Downloads
                </button>
              </div>
              <div className={`single-product__tab-content ${accordionOpen[3] && 'active'}`}>
                <div className='single-product__tab-row'>
                  <h4>Documents &amp; Downloads</h4>
                  <div>
                    { wpProduct.acf.downloads?.length > 0 &&
                      <ul>
                        { wpProduct.acf.downloads.map((el, i) => (
                          <li key={i}>
                            <a href={el.file.localFile.publicURL} download>{el.file.localFile.name}.{el.file.localFile.extension}</a>
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                </div>
              </div>
              <div className='single-product__mobile-tab'>
                <button type='button' className={`${accordionOpen[4] && 'active'}`} onClick={() => this._toggleAccordion(2)}>
                  Delivery &amp; Support
                </button>
              </div>
              <div className={`single-product__tab-content ${accordionOpen[4] && 'active'}`}>
                <div className='single-product__tab-row'>
                  <h4>Delivery &amp; Support</h4>
                  <div dangerouslySetInnerHTML={{ __html: wpProduct.acf.deliverySupport ? wpProduct.acf.deliverySupport : wp.siteOptions.settings.deliverySupport }} />
                </div>
              </div>
            </div>
          </section>
          <section className='single-product__related'>
            <div className='single-product__inner'>
              <h3>You may also like</h3>
              <div className='products__items'>
                { related?.length > 0 && related.map((el, i) => {
                  return (
                    <div className='products__item' key={i}>
                      <ProductCard {...el} />
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <ReadyToGetStarted />
        </article>
      </>
    )
  }

}

export default Product

export const productQuery = graphql`
  query ShopifyProductById($slug: String!) {
    allWpProduct (
      filter: {id: {ne: $slug}}
      limit: 4
    ) {
      nodes {
        title
        slug
        date
        materials {
          nodes {
            name
            slug
          }
        }
        shopifyProduct {
          images {
            src
            width
            height
          }
          variants {
            title
            price
          }
        }
      }
    }
    wpProduct(slug: {eq: $slug}) {
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        description
        features
        width
        length
        drop
        specifications {
          label
          value
        }
        downloads {
          file {
            localFile {
              publicURL
              name
              extension
            }
          }
        }
        warrantyReturns
        deliverySupport
      }
    }
    shopifyProduct(handle: { eq: $slug }) {
      title
      handle
      description
      descriptionHtml
      storefrontId
      images {
        originalSrc
        width
        height
      }
      variants {
        storefrontId
        title
        image {
          originalSrc
          width
          height
        }
        sku
        price
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
    }
    wp {
      siteOptions {
        settings {
          colours {
            name
            colour
          }
          warrantyReturns
          deliverySupport
        }
      }
    }
  }
`
